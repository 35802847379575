import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';

const initializeI18Next = (baseUrl, locale) => {
  return new Promise((resolve, reject) => {
    i18next.use(i18nextXHRBackend).init(
      {
        lng: locale,
        fallbackLng: 'en',
        keySeparator: '$',
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: `${baseUrl}app-settings_{{lng}}.json`,
          crossDomain: true,
        },
        react: {
          wait: true,
        },
      },
      (err) => (err ? reject(err) : resolve(i18next)),
    );
  });
};

const getTranslationsBaseUrl = async (sdk, token) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(token, forumAppDefId);
  const platformComponent = appData.components.find((c) => c.type === 'PLATFORM');
  const baseUrls = platformComponent && platformComponent.data && platformComponent.data.baseUrls;
  return baseUrls && baseUrls.translationsBaseUrl;
};

export default async (sdk, token) => {
  const translationsBaseUrl = await getTranslationsBaseUrl(sdk, token);
  const locale = await sdk.editor.environment.getLocale();
  return initializeI18Next(translationsBaseUrl, locale);
};
